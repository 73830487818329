.wrapper {
  width: 18px;
  height: 18px;
  animation: rotate 1.5s linear infinite;
  circle {
    stroke-dasharray: 150 200;
    stroke-dashoffset: -10;
    animation: dash 1s infinite;
    stroke-linecap: round;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89 200;
      stroke-dashoffset: -124;
    }
  }
}