.wrapper {
  header {
    max-width: calc(794rem/16);
    margin: 0 auto clamp(48px, calc(80vw/7.68), 96px);
    text-align: center;
    .paragraph {
      max-width: calc(588rem/16);
      margin: clamp(20px, calc(28vw/7.68), 28px) auto clamp(28px, calc(36vw/7.68), 36px);
    }
  }
  position: relative;
  .decoration {
    position: absolute;
    margin-left: calc(var(--pageMargin) * -1);
    @media (min-width: 1280px){
      margin-left: calc((100vw - 1200px) / -2);
    }
    left: 0;
    top: 0;
    transform: translateY(-70%);
    @media (max-width: 799px){
      transform: translateY(-85%);
    }
    width: clamp(92px, calc(158vw/7.68), 242px);
  }

  // List
  min-width: 0;
  .slider {
    margin: 0 calc(var(--pageMargin) * -1);
    padding: 0 var(--pageMargin);
    @media (min-width: 1280px) {
      margin: 0 calc((100vw - 1200px) / -2);
    }
    &:active {
      .item {
        transform: scale(.9);
      }
    }
    .slider-wrapper {
      display: flex;
      gap: clamp(20px, calc(20vw/7.68), 32px);
      align-items: stretch;
      &:has(.item:focus-visible){
        overflow-x: auto;
        transform: none !important;
      }
      height: 100%;
    }
    &[data-visible="true"]{
      .item {
        animation: slide 1.2s var(--easing) backwards;
        @keyframes slide {
          0% {
            opacity: 0;
            transform: translateX(100%);
          }
        }
      }
    }
    .item {
      transition: transform .8s var(--easing);
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .img {
        pointer-events: none;
        display: block;
        margin-bottom: clamp(12px, calc(20vw/7.68), 20px);
        overflow: hidden;
        transition: transform .8s var(--easing);
        transform: translateY(8px);
        img {
          transition: transform .8s var(--easing);
        }
      }
      &:hover,
      &:focus-visible {
        .img {
          transform: translateY(0);
          img {
            transform: scale(1.15);
          }
        }
        .text {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .text {
        transition: transform .8s var(--easing), opacity .8s var(--easing);
        opacity: 0;
        transform: translateY(-8px);
        .info {
          display: flex;
          gap: 4px clamp(12px, calc(20vw/7.68), 20px);
          flex-wrap: wrap;
          margin-bottom: 4px;
          font-size: clamp(calc(14rem/16), calc(16vw/7.68), calc(16rem/16));
        }
        .title {
          font-size: clamp(calc(18rem/16), calc(24vw/7.68), calc(24rem/16));
          line-height: 100%;
        }
      }
      &:nth-child(4n + 1) {
        width: clamp(98px, calc(130vw/7.68), 173px);
      }
      &:nth-child(4n + 2) {
        width: clamp(272px, calc(363vw/7.68), 484px);
      }
      &:nth-child(4n + 3) {
        width: clamp(213px, calc(284vw/7.68), 381px);
      }
      &:nth-child(4n + 4) {
        width: clamp(272px, calc(363vw/7.68), 484px);
      }
    }
  }
}

.cursor {
  @media (pointer: coarse) {
    display: none;
  }
  pointer-events: none;
  border-radius: 50%;
  background-color: var(--secondary-600);
  width: 64px;
  height: 64px;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}