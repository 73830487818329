.wrapper {
  max-width: calc(1000rem/16);
  margin: 0 auto;
  header {
    text-align: center;
    margin-bottom: clamp(28px, calc(80vw/7.68), 80px);
  }
  .list {
    details {
      border-bottom: 1px solid var(--secondary-900);
      summary {
        padding: clamp(24px, calc(32vw/7.68), 36px) 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span {
          display: block;
          max-width: calc(780rem/16);
          font-size: clamp(calc(18rem/16), calc(24vw/7.68), calc(24rem/16));
          font-style: italic;
        }
        > svg {
          transition: transform .8s var(--easing);
        }
      }
      .answer {
        overflow: hidden;
        padding: 0 12px;
        max-width: calc(780rem/16);
        > *:not(:last-child){
          margin-bottom: 12px;
        }
      }
      &[data-opened="true"]{
        summary {
          cursor: default;
          > svg {
            transform: rotateX(-180deg);
          }
        }
      }
    }
  }
}