.label {
  cursor: pointer;
  border: 1px solid var(--secondary-900);
  height: 145px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: border-color .5s var(--easing);
  font-size: 1rem;
  padding: 8px;
  input {
    opacity: 0;
    position: fixed;
  }
  .indicator {
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    border-radius: 50%;
    border: 1px solid var(--secondary-600);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color .5s var(--easing);
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background-color: var(--secondary-600);
      border-radius: inherit;
      transform: scale(0);
      transition: transform .5s var(--easing);
    }
  }
  &:hover,
  &:has(input:focus-visible) {
    border-color: var(--secondary-800);
  }
  &:has(input:checked) {
    border-color: var(--secondary-500);
    .indicator {
      &::before {
        transform: scale(1);
      }
    }
  }
  &:has(input:focus-visible) {
    outline: 1px solid var(--secondary-900);
    outline-offset: 5px;
  }
  &[aria-invalid="true"]{
    border-color: var(--error-400);
    .indicator {
      border-color: var(--error-400);
    }
  }
}