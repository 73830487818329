.wrapper {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--secondary-400);
  position: relative;
  padding: 11px 80px;
  text-align: center;
  z-index: 2;
  background-color: rgba(29,30,29,.8);
  backdrop-filter: blur(3px);
  @media (max-width: 499px) {
    padding: 11px 24px;
    width: 100%;
  }
  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-400);
    transform-origin: left;
    transform: scaleX(0);
    transition: transform .8s .1s var(--easing);
    z-index: -1;
  }
  transition: color .8s .1s var(--easing);
  svg {
    transition: transform .8s 0s var(--easing);
  }
  &:hover,
  &:focus-visible {
    color: var(--primary-700);
    transition-delay: 0s;
    &::before {
      transform: scaleX(1);
      transition-delay: 0s;
    }
    svg {
      transition-delay: .1s;
      transform: translateX(8px);
    }
  }
  &:disabled {
    opacity: .8;
    pointer-events: none;
  }
}